import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

interface ImageProps {
  src: string;
  alt?: string;
  className?: string;
  height?: string;
  width?: string;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  className,
  height,
  width,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => data.allFile.edges.find(({ node }: any) => src === node.relativePath),
    [data, src]
  );

  return (
    <Img
      fluid={match.node.childImageSharp.fluid}
      alt={alt}
      className={className}
      style={{ height: `${height}px`, width: `${width}px` }}
    />
  );
};

export default Image;

/**
 *
 */
