import React, { useEffect, useState, forwardRef } from 'react';
import Spinner from '../svgs/spinner.svg';

const Loader = forwardRef<HTMLElement>((_, ref) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const incrementer = setInterval(() => {
      const newProgress = progress + 1;
      setProgress(newProgress >= 360 ? 0 : newProgress);
    }, 0.5);
    return () => {
      clearInterval(incrementer);
    };
  });
  return (
    <Spinner
      ref={ref}
      style={{
        transform: `rotate(${progress}deg)`,
        transition: 'transform',
      }}
      fill="#fff"
    ></Spinner>
  );
});

Loader.displayName = 'Loader';
export default Loader;
