import React from 'react';

export interface ServiceCardProps {
  title?: string;
  children: React.ReactChild;
  icon?: React.ReactElement;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, children, icon }) => {
  return (
    <div
      className={`card rounded overflow-hidden border border-gray-400 hover:bg-zomp hover:shadow-2xl hover:text-white transform hover:-translate-y-3 duration-300 cursor-pointer h-full`}
    >
      <div className="px-5 py-12 text-center">
        <div className="mb-6">
          <span className="inline-block rounded-full bg-zomp p-3 text-white">
            {icon}
          </span>
        </div>
        <div className="text-xl mb-6 font-bold">{title}</div>
        <p className="text-gray-800 card-body">{children}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
