import React from 'react';

//@ts-ignore
export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  id: string;
  value?: string;
  className?: string;
  label?: string;
  style?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  id,
  value,
  className,
  label,
  onChange,
  style,
}) => {
  return (
    <div className="flex flex-col mb-4">
      <label htmlFor={id} className="mb-1">
        {label}
      </label>
      <input
        name={name}
        id={id}
        value={value}
        className={`bg-white focus:outline-none focus:shadow-outline border border-gray-400 rounded-lg py-2 px-4 block w-full appearance-none leading-normal ${className}`}
        type="text"
        onChange={onChange}
        //@ts-ignore - For lastpass issue
        style={style}
      ></input>
    </div>
  );
};

export default TextInput;
