import React from 'react';
import { Button } from '.';
import Image from './image';

export interface PortfolioCardProps {
  title?: string;
  image?: string;
  altText?: string;
  className?: string;
  description?: string;
  link?: string;
}

const PortfolioCard: React.FC<PortfolioCardProps> = ({
  title,
  image = '',
  altText,
  className,
  description,
  link,
}) => {
  return (
    <div
      className={`card rounded overflow-hidden bg-white shadow-md ${className}`}
    >
      <a className="portfolio-item block relative text-center" href={link}>
        {/* <picture>
          <source
            srcSet={`${require(`../assets/images/portfolio/${image}-400.webp`)} 400w, ${require(`../assets/images/portfolio/${image}-600.webp`)} 600w`}
            type="image/webp"
          />

          <source
            srcSet={
              require(`../assets/images/portfolio/${image}.png?resize&sizes[]=400&sizes[]=600`)
                .srcSet
            }
            type="image/png"
          />
          <img
            src={
              require(`../assets/images/portfolio/${image}.png?resize&sizes[]=400&sizes[]=600`)
                .srcSet
            }
            
          />
        </picture> */}
        <Image src={`portfolio/${image}.png`} alt={altText} />
        <div className="portfolio-caption text-white p-12">
          <h2 className="portfolio-caption__title text-xl font-bold mb-4">
            {title}
          </h2>
          <div className="portfolio-divider mb-4"></div>
          <p className="portfolio-caption__body mb-4">{description}</p>
          <Button className="portfolio-item__cta">See More</Button>
        </div>
      </a>
    </div>
  );
};

export default PortfolioCard;
