import React from 'react';
import Image from './image';

export interface TestimonialProps {
  message?: string;
  image?: string;
  imageAlt?: string;
  className?: string;
  author?: string;
  business?: string;
  webP?: string;
}

const imagePlaceholder = 'https://source.unsplash.com/random/300x300?face';

const Testimonial: React.FC<TestimonialProps> = ({
  message = '',
  image = imagePlaceholder,
  imageAlt = '',
  className,
  author = '',
  business = '',
}) => {
  const renderImage = () => {
    const className = 'rounded-full';
    // if (webP) {
    //   return (
    //     <picture>
    //       <source srcSet={webP} type="image/webp" />
    //       <source srcSet={image} type="image/jpeg" />
    //       <img
    //         className={className}
    //         src={image}
    //         alt={imageAlt}
    //         height="55"
    //         width="55"
    //       />
    //     </picture>
    //   );
    // }
    return (
      // <img
      //   className={className}
      //   src={image}
      //   alt={imageAlt}
      //   height="55"
      //   width="55"
      // />
      <Image
        className={className}
        src={image}
        alt={imageAlt}
        height="55"
        width="55"
      />
    );
  };
  return (
    <div
      className={`bg-white rounded-md shadow-lg py-4 md:py-6 px-4 md:px-6 testimonial ${className}`}
    >
      <div className="mb-4">
        <p className="text-black text-sm">&quot;{message}&quot;</p>
      </div>
      <div className="flex justify-start items-center">
        {renderImage()}
        <div className="ml-3">
          <h2 className="text-md font-bold">{author}</h2>
          <p className="text-sm text-gray-700">{business}</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
