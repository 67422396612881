import React, { useState, useEffect } from 'react';
import { Testimonial } from '.';

const data = [
  {
    id: 1,
    message: `Diondre was recommended to me to help tidy up my unfinished website that was done by another developer. I have found him to be professional, efficient and excellent at communicating. Updates to the site are completed quickly, even on weekends and public holidays. We have received many compliments from a range of people regarding our website and I would highly recommend Dewebgineer Solutions to anyone!`,
    author: 'Molissa Smith',
    business: 'Marketing Executive - Caribbean Home Swap',
    image: 'molissa.jpeg',
    // webP: require('../assets/images/molissa.jpeg?webp'),
    imageAlt: 'Molissa Smith - Caribbean Home Swap',
    classname: '',
  },
  // {
  //   id: 2,
  //   message: `The quality of work and professionalism shown by Diondre is one that I will always highlight as the benchmark for what a Developer should exemplify, not only is the process clearly outlined and handled, there is always a sense of ease knowing that a solution will always be established once he is on the project. i would HIGHLY recommend his services to anyone needing development.`,
  //   author: 'Chad Bristol-White',
  //   business: 'Modern Media Coordinator - Wi Connect Mobile inc.',
  //   image: 'https://source.unsplash.com/random/60x60?face',
  //   webP: 'https://source.unsplash.com/random/60x60?face',
  //   imageAlt: 'Chad Bristol-White',
  //   classname: '',
  // },
  {
    id: 3,
    message: `I personally endorse the talent, efficiency and development speed of Diondre Edwards and Dewebgineer Solutions. Diondre is able to take my ramblings about the business and effectively apply it to a technological framework that produces a result that exceeded my own expectations. I fully trust his expertise and I am happy to recommend his suitability for any task that he believes falls under his scope of talent.`,
    author: 'Dorian Winfield',
    business: 'Co-Founder - Cloud Vision Group',
    image: 'dorian.jpeg',
    // webP: require('../assets/images/dorian.jpeg?webp'),
    imageAlt: 'Dorian Winfield - Cloud Vision Group',
    classname: '',
  },
];

const Testimonials = ({ className = '' }) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const next = (current + 1) % data.length;
    const interval = setInterval(() => setCurrent(next), 5000);
    return () => clearInterval(interval);
  }, [current]);

  return (
    <div
      className={`flex flex-col justify-center h-full w-full mt-4 ${className}`}
    >
      <div className="relative h-full w-full md:flex items-center">
        {data.map(
          (
            { id, message, author, image, imageAlt, business, classname },
            index
          ) => (
            <Testimonial
              key={id}
              className={`z-10 ${
                index === current ? 'testimonial--show' : ''
              } ${classname}`}
              message={message}
              author={author}
              business={business}
              image={image}
              imageAlt={imageAlt}
            />
          )
        )}
      </div>

      <div className="controls flex justify-center py-2 space-x-1">
        {data.map((_, index) => (
          <button
            key={index}
            className={`outline-none control-dot-container focus:outline-none h-12 w-12`}
            onClick={() => setCurrent(index)}
            aria-label={`Go to Testimonial ${index}`}
          >
            <span
              className={`control-dot ${
                index === current && 'control-dot--active'
              }`}
            ></span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
