import React, { useState } from 'react';
import TextInput from './text-input';
import { Loader } from '.';
import Button from './button';

interface Submission {
  name: string;
  email: string;
  subject?: string;
  message: string;
}

const Contact: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState('');
  const [submission, setSubmission] = useState<Submission>({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const formChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSubmission({ ...submission, [event.target.name]: event.target.value });
  };

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const { name, email: _replyto, subject, message } = submission;
      await fetch('https://formspree.io/xaypogjl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, _replyto, subject, message }),
      });
      setIsLoading(false);
      setFeedback('Message sent successfully');
    } catch (err) {
      setError(err.message);
      console.error(err);
    }
  };

  return (
    <div className="px-8 text-black">
      <div className="opacity-0">
        <div className="">{feedback}</div>
        <div className="">{error}</div>
      </div>
      <form method="post" onSubmit={submitForm}>
        <TextInput
          label="Name"
          name="name"
          id="name"
          value={submission.name}
          onChange={formChange}
        />

        <TextInput
          label="Email"
          name="email"
          id="email"
          value={submission.email}
          onChange={formChange}
        />
        <TextInput
          label="Subject"
          name="subject"
          id="subject"
          value={submission.subject}
          onChange={formChange}
        />

        <div className="flex flex-col mb-4">
          <label htmlFor="message" className="mb-1">
            Message
          </label>
          <textarea
            name="message"
            id="message"
            cols={30}
            rows={8}
            className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
            value={submission.message}
            onChange={formChange}
          ></textarea>
        </div>
        <div>
          <Button disabled={isLoading}>
            {isLoading ? <Loader></Loader> : 'Get Started'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
